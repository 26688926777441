body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-card-body {
  padding: 8px !important;
  padding-top:12px!important;
}

.dShadow {
  text-shadow: 0px 5px 4px rgba(0,0,0,0.7),
  0px 9px 14px rgba(0,0,0,0.4),
  0px 19px 24px rgba(0,0,0,0.4);
}

@media screen and (max-width: 534px) {
  h1{
      font-size:2.5em !important;
  }
  h3 {
      font-size: 1.25em !important;
  }
}

a:hover {
  color: black !important;
}
a {
  color: white !important;
  text-decoration: none;
}

a.modal {
  color: #B00710 !important;
}

h1 {
  font-size: 5em;

}