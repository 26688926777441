
.App {
  text-align: center;
}

h1,
h2,
h3 {
  color: white;
}

.avatar {
  padding-top: 30%;
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

@media (max-width: 767px) {
  .fullscreen-bg__video {
    display: none;
  }
}

.iconRow .anticon>svg {
  width: 2em;
  height: 2em;
}

.cardRow .ant-card-body {
  padding-top: 8px !important;
}

@font-face {
  font-family: 'handwriting';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.cdnfonts.com/s/78848/Balnuettes.woff') format('woff');
}